import { render } from "./downloadNurseApk.vue?vue&type=template&id=2ddcfcc0&scoped=true"
import script from "./downloadNurseApk.vue?vue&type=script&lang=js"
export * from "./downloadNurseApk.vue?vue&type=script&lang=js"

import "./downloadNurseApk.vue?vue&type=style&index=0&id=2ddcfcc0&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-2ddcfcc0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2ddcfcc0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2ddcfcc0', script)) {
    api.reload('2ddcfcc0', script)
  }
  
  module.hot.accept("./downloadNurseApk.vue?vue&type=template&id=2ddcfcc0&scoped=true", () => {
    api.rerender('2ddcfcc0', render)
  })

}

script.__file = "src/views/static/about/downloadNurseApk.vue"

export default script